<template>
	<div class="f1 dF fC select-instance-page" v-if="instances">
		<div class="pb-4">
			<h1 class="align-center" :class="$mq">
				Welcome{{ instances.length ? " back" : "" }},
				{{ user.user.firstName }}
			</h1>
			<div v-if="instances.length" class="dF aC jC">
				<p class="text-lg">Select a Project</p>
				<p class="text-lg create-instance-text" v-if="$userType !== 'agent'">
					&nbsp;or&nbsp;<a class="text-primary" @click.prevent="$router.push('/instance/create')">create a new
						one</a>
				</p>
			</div>
			<div v-else-if="$userType !== 'agent'" class="dF aC jC">
				<p class="text-lg create-instance-text">
					<a class="text-primary" @click.prevent="$router.push('/instance/create')">Create a New Project</a>
				</p>
			</div>
			<div id="stickyNav" class="mx-auto mt-5" :class="$mq != 'sm' ? 'container' : ''">
				<div :class="instances.length ? toggleNavClass() : ''">
					<div class="dF aC" :style="$mq == 'sm' ? 'justify-content:center; width:100%' : ''">
						<div class="dF aC" :class="displayTab == 'invitation' || $mq === 'sm' ? '' : 'mr-7'"
							style="border-bottom: 1px solid #e4e7eb" :style="$mq === 'sm' ? '' : 'flex:1'">
							<div v-for="tab in tabs" :key="tab.name" @click="selectDisplay(tab.name)"
								style="cursor: pointer"
								:style="tab.name === displayTab ? 'border-bottom:3px solid var(--orange);' : ''"
								class="px-4">
								<a-badge v-if="tab.name === 'invitation'" :count="invitations.length"
									class="badge-position">
									<p style=" font-size: 15px; line-height: 22.5px; "
										:style="tab.name === displayTab ? 'color:#000;' : ''">{{ tab.label }}</p>
								</a-badge>
								<p v-else :style="tab.name === displayTab ? 'color:#000;' : ''">{{ tab.label }}</p>
							</div>
						</div>
						<a-input placeholder="Search your Projects..." v-model="query" ref="userNameInput" size="large"
							class="instance-search" v-if="$mq !== 'sm' && displayTab != 'invitation'">
							<a-tooltip slot="prefix" title="Search your Projects"
								overlayClassName="change-tooltip-color">
								<a-icon type="search" style="color: rgba(100, 100, 100, 0.45)" />
							</a-tooltip>
						</a-input>
					</div>
					<div v-if="displayTab != 'invitation'">
						<div class="dF aC p-4 mt-10" style="color: #757575">
							<div class="f1">Project Name</div>
							<div v-if="$mq != 'sm'" style="width: 241px">
								Last visited
							</div>
						</div>
						<hr style="border-width: 2px; margin: 0" />
					</div>
					<div v-else>
						<a-row class="p-4 mt-10" style="color: #757575">
							<a-col :span="$mq == 'sm' ? 10 : 5">User Name</a-col>
							<a-col v-if="$mq != 'sm'" :span="2">Role</a-col>
							<a-col v-if="$mq != 'sm'" :span="6">Email</a-col>
							<a-col :span="$mq == 'sm' ? 12 : 4">Project</a-col>
							<a-col :span="$mq == 'sm' ? 2 : 7"></a-col>
						</a-row>
						<hr style="border-width: 2px; margin: 0" />
					</div>
				</div>
			</div>
		</div>
		<div v-if="
			(!invitations.length && displayTab == 'invitation') ||
			(!instances.length && displayTab != 'invitation')
		" class="align-center pb-4">
			<!-- <h1>Welcome, {{user.user.firstName}}</h1> -->
			<img src="@/assets/noinstance.svg" style="height: 450px" class="mt-8" />
			<p class="text-lg mt-5">
				You don't have any
				{{ displayTab != "invitation" ? "projects" : "invitations" }}
				yet
			</p>
			<a-button v-if="displayTab != 'invitation'" type="primary"
				@click.prevent="$router.push('/instance/create')" style="width: 180px; height: 40px">CREATE
				PROJECT</a-button>
		</div>

		<div v-if="instances.length" class="container">
			<bh-loading :show="loading" />
			<AllInstances @updatePin="updatePin" v-if="displayTab == 'all'" :type="'all'"
				:instances="queriedInstances" />
			<AllInstances @updatePin="updatePin" v-if="displayTab == 'recent'" :type="'recent'"
				:instances="order(recentInstances)" />
			<AllInstances @updatePin="updatePin" v-if="displayTab == 'pin'" :type="'pin'"
				:instances="pinnedInstances" />
			<AllInstances @updatePin="updatePin" v-if="displayTab == 'demo'" :type="'demo'"
				:instances="demoInstances" />
		</div>
		<div v-if="invitations.length" class="container">
			<InvitationsTab @updateInvitations="updateInvitations" v-if="displayTab == 'invitation'" />
		</div>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import AllInstances from "./AllInstances";
	import InvitationsTab from "@/components/common/InvitationsTab";
	import { getInitial } from "bh-mod";
	import bhLoading from "bh-mod/components/common/Loading";
	import { mapActions } from "vuex";

	export default {
		components: {
			AllInstances,
			InvitationsTab,
			bhLoading,
		},
		computed: {
			user() {
				return this.$store.state.user.user;
			},

			showInvitation() {
				return this.$store.state.user.showInvitation;
			},
			userSettings() {
				if (
					this.$store.state.user.user.settings != null &&
					Object.keys(this.$store.state.user.user.settings).length != 0
				) {
					return this.$store.state.user.user.settings;
				} else return {};
			},
			pinnedInstances() {
				let instances = this.pinInstances;
				if (!this.query.trim()) {
					this.result = true;
					return instances;
				}
				let query = this.query.trim().toLowerCase();
				let tempResult = false;
				return instances.filter((x) => {
					let name = x.name.trim().toLowerCase();
					if (name.includes(query)) {
						this.result = true;
						tempResult = true;
					}
					if (!name.includes(query) && tempResult != true) {
						this.result = false;
					}
					return name.includes(query);
				});
			},
			recentInstances() {
				let instances = [];
				if (
					this.userSettings.options &&
					this.userSettings.options.lastVisited &&
					Object.keys(this.userSettings.options.lastVisited).length != 0
				) {
					// let visited = Object.values(this.userSettings.options.lastVisited)
					Object.keys(
						this.$store.state.user.user.settings.options.lastVisited
					).forEach((id) => {
						let object = {};
						let find = this.instances.find((x) => x.id == id);
						if (find) {
							object = JSON.parse(JSON.stringify(find));
							find.lastVisited =
								this.$store.state.user.user.settings.options.lastVisited[
								id
								];
							instances.push(object);
						}
					});
				} else {
					instances = [];
				}
				if (!this.query.trim()) {
					this.result = true;
					return instances;
				}
				let query = this.query.trim().toLowerCase();
				let tempResult = false;
				return instances.filter((x) => {
					let name = x.name.trim().toLowerCase();
					if (name.includes(query)) {
						this.result = true;
						tempResult = true;
					}
					if (!name.includes(query) && tempResult != true) {
						this.result = false;
					}
					return name.includes(query);
				});
			},
			screenWidth() {
				return window.innerWidth;
			},
			linkedInstances() {
				let linked = [];
				this.instances.forEach((instance) => {
					if (instance.children && instance.children.length != 0) {
						if (!linked.includes(instance.id)) {
							linked.push(instance.id);
						}
						instance.children.forEach((child) => {
							if (
								typeof child == "Object" &&
								!linked.includes(child.id)
							) {
								linked.push(child.id);
							} else if (
								typeof child != "Object" &&
								!linked.includes(child)
							) {
								linked.push(child);
							}
						});
					}
				});
				return linked;
			},
			instances() {
				return this.$store.state.instances;
			},
			queriedInstances() {
				let instances = this.allInstances;
				if (!this.query.trim()) {
					this.result = true;
					return instances;
				}
				let query = this.query.trim().toLowerCase();
				let tempResult = false;
				return instances.filter((x) => {
					let name = x.name.trim().toLowerCase();
					if (name.includes(query)) {
						this.result = true;
						tempResult = true;
					}
					if (!name.includes(query) && tempResult != true) {
						this.result = false;
					}
					return name.includes(query);
				});
			},
			...mapState(["user"]),

			invitations() {
				return this.$store.state.invitations
			},

			demoInstances() {
				return this.instances.filter(i => i.isDemo)
			},

			tabs() {
				let list = [
					{ name: 'all', label: 'All' },
					{ name: 'recent', label: 'Recent' },
					{ name: 'pin', label: 'Pin' },
					{ name: 'invitation', label: 'Invitations' },

				];
				if (this.demoInstances.length) {
					list.push({ name: 'demo', label: 'Demo' })
				}
				return list
			},
		},
		data() {
			return {
				loading: false,
				query: "",
				result: true,
				displayTab: "all",
				active: false,
				allInstances: [],
				pinInstances: [],
				warningKey: '2fa setup warning notification',
			};
		},
		watch: {
			showInvitation: {
				handler(val) {
					if (val) {
						this.displayTab = "invitation";
						this.$store.commit("HIDE_INVITATION");
					}
				},
			}
		},
		methods: {

			updateInvitations(id, instance) {
				if (instance) this.allInstances.unshift(instance);
				this.displayTab = "all";
			},
			order(array) {
				array = array.sort((a, b) => {
					if (
						a.lastVisited &&
						b.lastVisited &&
						a.lastVisited > b.lastVisited
					)
						return -1;
					if (
						a.lastVisited &&
						b.lastVisited &&
						a.lastVisited < b.lastVisited
					)
						return 1;
					else return 0;
				});
				return array;
			},
			updatePin(object) {
				let index = this.pinnedInstances.findIndex(
					(inst) => inst.id == object.id
				);
				if (index != -1) {
					this.pinnedInstances.splice(index, 1);
				} else {
					this.pinnedInstances.unshift(object);
				}
			},
			selectDisplay(type) {
				this.displayTab = type;
			},
			getName(name) {
				if (name.length > 20) {
					return name.substring(0, 20).concat("...");
				} else {
					return name;
				}
			},
			getInitial,
			getAvatar(inst) {
				if (inst.avatar) return inst.avatar;
				return `https://ui-avatars.com/api/?name=${inst.name
					.split(" ")
					.join("+")}`;
			},
			toggleNavClass() {
				if (this.active == false) {
					return "";
				} else {
					return "sticky-nav";
				}
			},

			updateInstances() {
				let allInstances = [];
				let pinInstances = [];
				if (this.userSettings?.options?.reOrder?.all?.length) {
					this.instances.forEach((inst) => {
						if (!this.userSettings.options.reOrder.all.includes(inst.id))
							allInstances.unshift(inst);
					});
					this.userSettings.options.reOrder.all.forEach((id) => {
						let find = this.instances.find((inst) => inst.id == id);
						if (find) allInstances.push(find);
					});
				} else {
					allInstances = this.instances;
				}
				if (this.userSettings?.options?.reOrder?.pin?.length && this.userSettings?.options?.pin) {
					this.userSettings.options.pin.forEach((id) => {
						let find = this.instances.find((inst) => inst.id == id);
						if (
							find &&
							!this.userSettings.options.reOrder.pin.includes(find.id)
						)
							pinInstances.push(find);
					});
					this.userSettings.options.reOrder.pin.forEach((id) => {
						let find = this.instances.find((inst) => inst.id == id);
						if (
							this.userSettings.options.pin.includes(id) &&
							find &&
							!pinInstances.find((inst) => inst.id == find.id)
						)
							pinInstances.push(find);
					});
				} else if (this.userSettings?.options?.pin && this.userSettings.options.pin.length !== 0) {
					this.userSettings.options.pin.forEach((id) => {
						let find = this.instances.find((x) => x.id == id);
						if (find) {
							pinInstances.push(find);
						}
					});
				} else {
					pinInstances = [];
				}

				this.allInstances = allInstances;
				this.pinInstances = pinInstances;
			}
		},
		async created() {
			this.updateInstances()

			if (this.$route.path.includes("tab=invitation")) {
				this.displayTab = "invitation";
				const invitationFor = this.$route.query.email;
				const loggedInUser = this.$store.state.user.user.email;
				if (invitationFor && invitationFor !== loggedInUser) {
					this.$message.error(
						`The invite is for ${invitationFor}, log out to accept invitation.`,
						6
					);
				}
			}

			if (this.showInvitation) {
				this.displayTab = "invitation";
				this.$store.commit("HIDE_INVITATION");
			}
			try {
				this.loading = true;
				let { data } = await this.$api.get("/user-invites/me");
				this.$store.commit('SET_INVITATIONS', data)
				this.loading = false;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
		},
		mounted() {
			let user = this.$store.state.user.user || {}
			if (!user['2faEnabled'] && user.provider !== 'sso') {
				this.$notification.close(this.warningKey)
				this.$notification.open({
					message: h => <h4>Account Security</h4>,
					description: h => <h6>For enhanced security measures on your account, please activate two-factor authentication (2FA). Click on secure to begin the setup process.</h6>,
					btn: h => {
						return [
							h(
								'a-button',
								{
									on: {
										click: () => this.$notification.close(this.warningKey),
									},
								},
								'DISMISS',
							),
							h(
								'a-button',
								{
									props: {
										type: 'primary',
									},
									class: 'ml-3',
									on: {
										click: () => {
											this.$notification.close(this.warningKey)
											this.$router.push('settings/accounts?2FA')
										},
									},
								},
								'SECURE',
							)
						];
					},
					key: this.warningKey,
					onClose: close,
					duration: 0,
				});
			}

			window.document.onscroll = () => {
				let topBar = document.querySelector(".top-bar--fixed");
				let topBarHeight = topBar && topBar.offsetHeight;
				let navBar = document.getElementById("stickyNav");
				if (navBar) {
					let offset = navBar.offsetTop - topBarHeight - 45;
					if (window.scrollY > offset) {
						this.active = true;
					} else {
						this.active = false;
					}
				}
			};
		},
	};
</script>

<style lang="scss" module>
	@import "./style.module.scss";
</style>
<style lang="scss">
	.badge-position .ant-badge-count {
		top: -2px !important;
		right: -8px !important;
	}

	.bh-layout {
		background-color: white;
	}

	.iItem {
		transition: all 0.3s;
	}

	.instance-card {
		background-color: white;
		border-radius: 3px;
		border-color: rgba(63, 63, 63, 0.15);
		box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15);
		border: none;
	}

	.instance-search {
		width: 400px;

		input {
			background-color: #fff;
			border-right: 0;
			border-top: 0;
			border-left: 0;
		}
	}

	@media screen and (min-width: 900px) {
		.instances-grid {
			max-width: 900px;
			position: relative;
			display: grid;
			grid-template-columns: 380px 380px;
			grid-gap: 30px;
		}
	}

	@media screen and (max-width: 900px) {
		.instances-grid {
			max-width: 900px;
			position: relative;
			display: grid;
			grid-template-columns: 380px;
			grid-gap: 30px;
		}
	}

	@media screen and (max-width: 400px) {
		.instances-grid {
			max-width: 900px;
			position: relative;
			display: grid;
			grid-template-columns: 300px;
			grid-gap: 30px;
		}
	}

	.instance-avatar {
		svg {
			width: 50px;
			height: 50px;
		}

		.st0 {
			clip-path: url(#SVGID_2_);
		}
	}

	.select-instance-page {
		@media screen and (min-width: 768px) {
			padding-top: 15%;
		}

		@media screen and (max-width: 768px) {
			padding-top: 105px;
		}
	}

	.create-instance-text {
		@media screen and (max-width: 700px) {
			display: none;
		}

		@media screen and (min-width: 700px) {
			display: flex;
		}
	}
</style>
<style scoped lang="scss">
	.popover-option {
		cursor: pointer;
		height: 35px;
		width: 100px;
		line-height: 35px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.popover-option:hover {
		background-color: #faf9f9;
		color: #000;
	}

	.hexagon {
		overflow: hidden;
		visibility: hidden;
		-webkit-transform: rotate(120deg);
		-moz-transform: rotate(120deg);
		-ms-transform: rotate(120deg);
		-o-transform: rotate(120deg);
		transform: rotate(120deg);
		cursor: pointer;
	}

	.hexagon-in1 {
		overflow: hidden;
		width: 100%;
		height: 100%;
		-webkit-transform: rotate(-60deg);
		-moz-transform: rotate(-60deg);
		-ms-transform: rotate(-60deg);
		-o-transform: rotate(-60deg);
		transform: rotate(-60deg);
	}

	.hexagon-in2 {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 50%;
		background-color: #d0c9d6;
		visibility: visible;
		-webkit-transform: rotate(-60deg);
		-moz-transform: rotate(-60deg);
		-ms-transform: rotate(-60deg);
		-o-transform: rotate(-60deg);
		transform: rotate(-60deg);
	}

	.hexagon2 {
		width: 25px;
		height: 50px;
		margin: -20px 0 -20px 0;
	}

	.icon-option {
		width: 25px;
		height: 100%;
		display: flex;
		align-items: center;
	}

	.option-button {
		text-align: center;
		height: 25px;
	}

	.instance-row:hover {
		.hide-hexagon {
			display: none;
		}

		.option-button {
			display: block;
		}
	}

	.instance-row:not(:hover) {
		.hide-hexagon {
			display: block;
		}

		.option-button {
			display: none;
		}
	}
</style>
<style scoped>
	.instance-row:hover {
		background-color: #faf9f9;
	}
</style>
<style lang="scss">
	.change-tooltip-color .ant-tooltip-content .ant-tooltip-inner {
		background: #3f3356;
	}
</style>
<style scoped>

	/* two classes, decided on scroll */
	.sticky-nav {
		transition: 10ms;
		padding-top: 20px;
	}

	.sticky-nav {
		transition: 10ms;
		position: fixed;

		background-color: #fff;
		width: 100%;
		z-index: 10;
		left: 0;
		top: 69px;
	}

	@media (min-width: 576px) {
		.sticky-nav {
			top: 69px;
			left: auto;
			max-width: 510px;
		}
	}

	@media (min-width: 768px) {
		.sticky-nav {
			max-width: 690px;
		}
	}

	@media (min-width: 992px) {
		.sticky-nav {
			max-width: 930px;
		}
	}

	@media (min-width: 1200px) {
		.sticky-nav {
			max-width: 1110px;
		}
	}
</style>
